<template>
  <div class="member" v-if="!isProcessing">
    <h3 class="member__title">会員数</h3>
    <p class="member__number">{{ activeUIDs.length }}<span class="home__member__number__unit">人</span></p>
    <div class="member__lists">
      <member-user class="member__lists__item" v-for="uid in activeUIDs" :key="uid" :uid="uid" :user="users[uid]" />
    </div>
  </div>
</template>

<script>
import MemberUser from '@/components/member/user'

export default {
  components: { MemberUser },
  name: 'member',
  async mounted () {
    // 会員一覧を取得しない場合はNotFoundへ遷移
    if (!this.settingDisplayed.filter(setting => setting.type === 'member').length) {
      this.$router.push({ name: 'notfound' })
      return
    }

    const promises = []
    // 自分しかいない場合は全ユーザーを取得
    if (Object.keys(this.users).length === 1) promises.push(this.$store.dispatch('users/getUsers'))

    // アクティブユーザーを取得
    if (!this.activeUIDs.length) promises.push(this.$store.dispatch('functions/getActiveUIDs'))
    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object} ユーザー一覧
     */
    users () {
      return this.$store.getters['users/users']
    },
    /**
     * @return {Object} 表示有無の設定情報
     */
    settingDisplayed () {
      return this.$store.getters['settingDisplayed/settingDisplayed']
    },
    /**
     * @return {String[]} アクティブなユーザーの一覧
     */
    activeUIDs () {
      return this.$store.getters.activeUIDs
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";

.member {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__title {
    font-size: 1.2rem;
    font-weight: normal;
    text-align: center;
  }
  &__number {
    margin: 5px 0 0;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    &__unit {
      font-size: 1.2rem;
      font-weight: normal;
    }
  }
  &__lists {
    margin-top: 20px;
  }
}
</style>
