<template>
  <div class="home-user" @click="$router.push({ name: 'profile', params: { uid: uid } })">
    <v-img class="home-user__icon" :src="user.icon" />
    <p class="home-user__name">{{ user.name }}</p>
  </div>
</template>

<script>
export default {
  props: {
    // ユーザーID
    uid: {
      type: String,
      required: true
    },
    // ユーザー情報
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.home-user {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin-left: -$padding_width;
  border-bottom: 1px solid $gray_lighten_color;
  &__icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    vertical-align: middle;
    background-color: $gray_lighten_color;
    border-radius: 50%;
  }
  &__name {
    display: inline-block;
    margin: 0 0 0 15px;
    font-size: 1.8rem;
    vertical-align: middle;
  }
}
</style>
